import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Training } from "@app/shared/models/entities/Training";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { Exam } from "./models/Exam";
import { IExamAttempt } from "./models/IExamAttempt";
import { UtilService } from "@app/shared/services/util.service";
import { Assignment } from "@app/shared/models/entities/course/Assignment";
import { OldExamAssignment } from "./models/OldExamAssignment";
import { UserTrainingExamStats } from "./models/UserTrainingExamStats";
import { ExamUserAnswer } from "@app/user/models/ExamUserAnswer";

interface OldExamAssignmentBody extends Omit<OldExamAssignment, "assignment"> {
  assignment: number;
}

export interface CreateExamAttemptBody extends Partial<Omit<IExamAttempt, "training" | "courses" | "domains">> {
  training: number;
  courses?: number[];
  domains?: number[];
}

export interface GetOldExamAssignmentsParams {
  old_exam_id?: number;
  assignment_id?: string;
  training_id?: number;
  simple?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class ExamService {
  apiEndpoint: string = environment.apiEndpoint + "/exams/";

  constructor(
    private http: HttpClient,
    private utilService: UtilService
  ) {}

  getOldExam(oldExamId: number): Observable<Exam> {
    return this.http.get<Exam>(environment.apiEndpoint + "/old-exams/" + oldExamId);
  }

  getTrainings(userLevel: number): Observable<Training[]> {
    return this.http.get<Training[]>(this.apiEndpoint + "trainings?level=" + userLevel + "&type=" + 0);
  }

  getTrainingOldExams(trainingId: number, params?: { is_live?: boolean }): Observable<Exam[]> {
    return this.http.get<Exam[]>(
      this.apiEndpoint + "trainings/" + trainingId + "/exams?" + this.utilService.dictionaryToQueryParams(params)
    );
  }

  getOldExams(params?: { training_id?: number; is_live?: boolean }): Observable<Exam[]> {
    return this.http.get<Exam[]>(environment.apiEndpoint + "/old-exams?" + this.utilService.dictionaryToQueryParams(params));
  }

  createOldExam(data: Partial<Exam>): Observable<Exam> {
    return this.http.post<Exam>(environment.apiEndpoint + "/old-exams", data);
  }

  scrapeOldExam(oldExam: Exam, formData: FormData): Observable<any> {
    return this.http.post<any>(environment.apiEndpoint + "/old-exams/" + oldExam.id + "/scrape", formData);
  }

  updateOldExam(oldExam: Exam, data: Partial<Exam>): Observable<Exam> {
    return this.http.put<Exam>(environment.apiEndpoint + "/old-exams/" + oldExam.id, data);
  }

  getOldExamAssignments(params: GetOldExamAssignmentsParams): Observable<OldExamAssignment[]> {
    return this.http.get<OldExamAssignment[]>(
      environment.apiEndpoint + "/old-exam-assignments?" + this.utilService.dictionaryToQueryParams(params as Record<string, string>)
    );
  }

  createOldExamAssignment(assignment: Assignment, oldExamId: number, index: number): Observable<OldExamAssignment> {
    return this.http.post<OldExamAssignment>(environment.apiEndpoint + "/old-exam-assignments", {
      assignment: assignment.id,
      old_exam: oldExamId,
      index: index,
    });
  }

  updateOldExamAssignments(oldExamAssignments: OldExamAssignment[]): Observable<any> {
    const data: OldExamAssignmentBody[] = [];
    oldExamAssignments.forEach((oldExamAssignment) => {
      data.push({
        id: oldExamAssignment.id,
        index: oldExamAssignment.index,
        assignment: (oldExamAssignment.assignment.id ? oldExamAssignment.assignment.id : oldExamAssignment.assignment) as number,
        old_exam: oldExamAssignment.old_exam,
      });
    });
    return this.http.put<any>(environment.apiEndpoint + "/old-exam-assignments", data);
  }

  removeOldExamAssignment(oldExamAssignment: OldExamAssignment): Observable<any> {
    return this.http.delete<any>(environment.apiEndpoint + "/old-exam-assignments/" + oldExamAssignment.id);
  }

  createExamAttempt(body: CreateExamAttemptBody): Observable<IExamAttempt> {
    return this.http.post<IExamAttempt>(this.apiEndpoint + "exam-attempts/", body);
  }

  getExamAttempts(params: { status?: string; training_id?: number; old_exam_id?: number }): Observable<IExamAttempt[]> {
    return this.http.get<IExamAttempt[]>(this.apiEndpoint + "exam-attempts?" + this.utilService.dictionaryToQueryParams(params));
  }

  getExamAttempt(examAttemptId: number): Observable<any> {
    return this.http.get<any>(this.apiEndpoint + "exam-attempts/" + examAttemptId);
  }

  updateExamAttempt(status: any, examAttemptId: number): Observable<any> {
    return this.http.patch<any>(this.apiEndpoint + "exam-attempts/" + examAttemptId, status);
  }

  createUserAnswer(examAttemptId: number, assignmentId: number, body: any): Observable<ExamUserAnswer> {
    return this.http.post<ExamUserAnswer>(
      this.apiEndpoint + "exam-attempts/" + examAttemptId + "/assignments/" + assignmentId + "/answers",
      body
    );
  }

  updateUserAnswer(userAnswerId: number, body: any): Observable<ExamUserAnswer> {
    return this.http.patch<ExamUserAnswer>(this.apiEndpoint + "answers/" + userAnswerId, body);
  }

  getExamAttemptScore(mode: string, examAttemptId: number): Observable<any> {
    return this.http.get<any>(this.apiEndpoint + "exam-attempts/" + examAttemptId + "/scores?score_mode=" + mode);
  }

  getTraining(trainingId: number): Observable<Training> {
    return this.http.get<Training>(environment.apiEndpoint + "/trainings/" + trainingId);
  }

  getUserExamTrainingStats(params?: { ids?: string }): Observable<UserTrainingExamStats[]> {
    return this.http.get<UserTrainingExamStats[]>(
      environment.apiEndpoint + "/user/trainings/exams/stats/?" + this.utilService.dictionaryToQueryParams(params)
    );
  }
}
